import React from 'react';
import iosImage from "../images/iphone.svg";
import android from "../images/pixel.svg";
import websiteImage from "../images/iphone.svg"; // Update this to the correct image
import close from "../images/close.png"

const Cart = ({ selectedOptions,onRemove,Dummy,baseUrl }) => {
  // console.log("Dummycart",Dummy.data)
  
  const imageSources = {
      
  };
  const featureImagePaths = {};
  
  // Loop through the dummy data to extract feature image paths
  Dummy && Dummy.forEach(section => {
    section.features_listing.forEach(featureCategory => {
      featureCategory.features.forEach(feature => {
        featureImagePaths[feature.feature_id] = feature.thumbnail
        || '/default_image_path.png';
      });
    });
  });
  
  // 'featureImagePaths' now contains feature IDs mapped to their respective image paths
  //  console.log("thumnail",featureImagePaths);
  Object.keys(featureImagePaths).forEach(featureId => {
    imageSources[featureId] = { image: featureImagePaths[featureId] };
  });
  const transformedImageSources = {};
Dummy && Dummy.forEach(section => {
  section.features_listing.forEach(featureCategory => {
    featureCategory.features.forEach(feature => {
      transformedImageSources[feature.feature_id] = {
        ...imageSources[feature.feature_id],
        name: feature.feature_title,
        description: feature.feature_description 
      };
    });
  });
});
  
  //console.log("Transformed image sources:", transformedImageSources);
  const categories = [
    { category: 'Selected Platforms', options: selectedOptions.platforms },
    { category: 'Selected Domain', options: selectedOptions.domain },
    { category: 'Selected Features', options: selectedOptions.features },
    { category: 'Selected Integrations', options: selectedOptions.integrations },
    { category: 'Selected Users', options: selectedOptions.users },
    { category: 'Selected Revenues', options: selectedOptions.revenues },
    { category: 'Selected Maintenances', options: selectedOptions.maintenances },
  ];
 
  
  
 

 
  return(
    <div className="col-xxl-1 col-xl-2  h-100 order-cst-2">
      <div className="border-box p-2 h-100">
          <h3 className="spacer " style={{ textAlign: 'center', height: '50px' }}>Selected Items</h3>
          <div className="cart-scroll" style={{ height: '76%', overflowY: 'auto' }}>
            {categories.map((category, index) => {
              if (category.options.length > 0) {
                return (
                  <div key={index}>
                    <h4 className="text-center">{category.category}</h4>
                    <div className="cart-list">
                      {category.options.map((option, optionIndex) => (
                        <div className={`${category.category.toLowerCase().split(' ')[1]}-items`} key={optionIndex}>
                          <div className="platform-items text-center">
                            <button onClick={() => onRemove(category.category, option)} className='remove-button'>
                              <img src={close} alt="Close" />
                            </button>
                            <img src={`${baseUrl}/${transformedImageSources[option].image}`}  width="50px" alt={option} />
                            
                          </div>
                          <h5 style={{ textAlign: 'center', fontSize:"13px",fontWeight:"bold" }}>{transformedImageSources[option].name}</h5>
                        </div>
                      ))}
                    </div>
                    {index !== categories.length - 1 && ( // Add a spacer for all categories except the last one
                      <div className="spacer" style={{ height: '30px' }} />
                    )}
                  </div>
                );
              }
              return null;
            })}
            <br />
          </div>
      </div>
    </div>
  );
};

export default Cart;
