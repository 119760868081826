import React from 'react'
import image from "../images/404.webp"
const NoPage = () => {
  return (
    <div>
    <img src={image} style={{display:"flex", margin:"auto" , animation: "bounce 2s infinite", }}  />
   
    </div>
  )
}

export default NoPage
