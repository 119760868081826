import React, { useEffect, useState } from 'react'

const Maintanence = ({onOptionsChange,selectedOptions,Dummy,setselectSectionData,data1}) => {
  const [sectionData, setSectionData] = useState(null);

  useEffect(() => {
    if (Dummy) {
      // Find the section based on section_id
      const foundSection = Dummy.find(
        (section) => section.section_id === selectedOptions.section_id.maintenances
      );
   
      if (foundSection) {
        setSectionData(foundSection);
      }
    }
    onOptionsChange(selectedOptions.maintenances, "maintenances");
  }, []);

  const handleCheckboxChange = (value) => {
    const updatedmaintenances = [...selectedOptions.maintenances]; // Make a copy of the existing platforms

    // Check if the value (feature_id) exists in the selected options
    const index = updatedmaintenances.indexOf(value);

    if (index === -1) {
      // If the value doesn't exist, add it to the platforms
      updatedmaintenances.push(value);
    } else {
      // If the value exists, remove it from the platforms
      updatedmaintenances.splice(index, 1);
    }

    // Update the selectedOptions state in the App component by calling onOptionsChange
    onOptionsChange(updatedmaintenances, "maintenances");
    const section_features = sectionData.features_listing
    .flatMap((listing) => listing.features)
    .filter((feature) =>updatedmaintenances.includes(feature.feature_id))
    .map((feature) => ({
      id: feature.feature_id,
      thumbnail: feature.thumbnail,
      estimated_cost:feature.estimated_cost,
      estimated_hours:feature.estimated_hours
    }));
  // Pass the checked features to the selectedSection prop
   //console.log("checkedFeatures",{estimate_id:data1,selectedOptions:{section_id:sectionData.section_id,section_features,}});
   setselectSectionData({estimate_id:data1,selectedOptions:{section_id:sectionData.section_id,section_features,}});
  };

  const isChecked = (value) => {
    return selectedOptions.maintenances.includes(value);
  };

  return (
    <>
      {sectionData && (
        <div
          id="menu1"
          className="h-100 tab-pane active"
          key={sectionData.section_id}
        >
          <br />
          <h1>{sectionData.section_title}</h1>
          <p dangerouslySetInnerHTML={{ __html: sectionData.section_description }} />
          <div className="platform-panel ps-4">
            {sectionData.features_listing.map((featureCategory, index) => (
             <React.Fragment key={index}>
                <h2>{featureCategory.feature_category_title}</h2>
                <p className="feature-width mb-0"dangerouslySetInnerHTML={{ __html: featureCategory.feature_category_description }} />
                <div className="platform-list ps-4">
                  {featureCategory.features.map((feature) => (
                    <div key={feature.feature_id}>
                      <label
                        htmlFor={`domain-${feature.feature_id}`}
                        className="cst-checkbox w-100"
                      >
                        <div className="platform-card">
                          <div className="platform-description">
                            <h3>{feature.feature_title}</h3>
                            <p className="feature-width mb-0 description" dangerouslySetInnerHTML={{ __html: feature.feature_description }} />
                          </div>
                          <input
                            type="checkbox"
                            name="rGroup"
                            value={feature.feature_id}
                            id={`domain-${feature.feature_id}`}
                            checked={isChecked(feature.feature_id)}
                            onChange={() =>
                              handleCheckboxChange(feature.feature_id)
                            }
                          />
                          <span className="checkmark"></span>
                        </div>
                      </label>
                    </div>
                  ))}
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default Maintanence
