import React from 'react'

const Footer = () => {
  return (
    <footer className="fixed-bottom bg-white p-3">
  <div className="container-fluid">
    <div className="copy-right">
      <span>© 2023 Kitlabs App Cost Estimator. All Rights Reserved</span>
    </div>
  </div>
</footer>
  )
}

export default Footer
