import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import logo from "../images/logo.svg"
import { BallTriangle } from 'react-loader-spinner';
const Success = ({ baseUrl}) => {
  const[loading,setLoading]=useState(true);
   const {id}= useParams();
   console.log(id);
   const [data, setData] =useState(null);
   
   useEffect(() => {
   
    async function fetchData() {
      try {
        
        const response = await axios.post(
          `${baseUrl}/api/get-estimate-details`,
          { estimate_id: id },
          { headers: { frontendToken: 'costesti' } }
        );
        console.log('Response:', response.data.data);
        setData(response.data.data);
        // Set the response data to state or do something with it as needed
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      
      }
    }

    fetchData();
  }, [id]);
   
  return (
    <>
    {loading ? (
          <div id="isloading">
            <BallTriangle color="#00BFFF" height={80} width={80} />
          </div>
        ) : (
          
        <>
        <header >
      <nav className="navbar navbar-expand-lg bg-white">
        <div className="container-fluid">
          <div className='d-flex flex-column flex-md-row justify-content-between w-100'>
            <a href="/"  className="navbar-brand" >
              <img src={logo} alt="Logo" />
            </a>
            <div className="d-flex align-items-center justify-content-center" id="">
              
              <div className="quote ms-3">
                <a href="https://www.kitlabs.us/request-a-quote/"  target="_blank" className="btn btn-blue">Get a Quote <img src="images/arrow-right.svg" alt="" /></a>
              </div></div>

          </div>
        </div>
      </nav>
    </header>
    <section className="content">
    <div className="container-fluid h-100">
      <div className="content-form h-100 ">
      <div className="row">
      {data && data.requester_details !== '' && (
        <div className="col-sm-12">
          <div className="card mb-3">
            <div className="card-inner-content">
              <div className="row">
              <h2 className='mt-3 thank'>Customer Details</h2>  
              <div className="col-sm-4">        
                <p className='d-inline-block'><strong>Estimate Id: </strong> {data.requester_details.estimate_id}</p>
              </div>
              <div className="col-sm-4">        
                <p className='d-inline-block'><strong>Name: </strong> {data.requester_details.requester_name}</p>
              </div>

              <div className="col-sm-4"> 
                <p className='d-inline-block'><strong>Email: </strong>{data.requester_details.requester_email}</p>
              </div>

              <div className="col-sm-4"> 
                <p className='d-inline-block'><strong>Phone Number: </strong>{data.requester_details.requester_phone_number}</p>
              </div>

              <div className="col-sm-4"> 
                <p><strong>Estimated Hours: </strong>{data.requester_details.total_estimated_hours}</p>
              </div>

              <div className="col-sm-4"> 
              <p><strong>Estimated Cost: </strong>$ {data.requester_details.total_estimated_cost}</p>
              </div>

              <div className="col-sm-12"> 
                <p><strong>Comments: </strong>{data.requester_details.requester_comments}</p> 
              </div>
              </div>
            <div>
             <a href ="/" className="btn btn-blue mt-3 " >
                
                Go to Calculator
              </a>
              </div>
          </div>
          </div>
        </div>
        )}


          <div className="col-sm-12">
             <div className="card mb-3">
             <h2 className='mt-3 thank'>Selected Features</h2>
          {data &&
        data.selectedOptions.map((section, index) => (
          <div className="card-inner-content">
          <div className="row" key={index}>
           
           
              <h3 className='mt-3' style={ {fontSize: '18px'}}>{section.section_name}</h3>
              {section.section_features.map((feature, idx) => (
                <div key={idx}  className="col-sm-2 mt-4">
                  
                    <div className="card-thumbnail ">
                      <img
                        src={feature.thumbnail}
                        className="rounded mx-auto d-block col-sm-4 mobile-image "
                        alt={feature.name}
                       
                      />
                      
                    </div>
                    <h3 className="mt-2 text-dark text-center" style={ {fontSize: '15px',
  textDecoration: 'none'}}>
                      {feature.name}
                    </h3>
                    
                  
                </div>
              ))}
            </div>
          </div>
          
        ))}
      </div>
    
        </div>
        </div>
          </div>
    </div>
    
   
    </section>
    </>)
   } 
   
    </>
  );
    };
    
  


export default Success
