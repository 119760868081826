import React, { useEffect, useState } from "react";

const Platforms = ({ onOptionsChange, selectedOptions, Dummy,setselectSectionData,data1 }) => {
  const [sectionData, setSectionData] = useState(null);
  //  console.log("Data atPlatform",Dummy);
  useEffect(() => {
    
      // Find the section based on section_id
      const foundSection = Dummy && Dummy.find(
        (section) => section.section_id === selectedOptions.section_id.platforms
      );
      // console.log("DataatPlatformSection12",foundSection);
      if (foundSection) {
        setSectionData(foundSection);
      }
    
    onOptionsChange(selectedOptions.platforms, "platforms");
  }, []);
//  console.log("DataSection",sectionData)





  const handleCheckboxChange = (value) => {
    const updatedPlatforms = [...selectedOptions.platforms]; // Make a copy of the existing platforms
          //  console.log("value",value)
    // Check if the value (feature_id) exists in the selected options
    const index = updatedPlatforms.indexOf(value);
      // console.log("787878",(index))
    if (index === -1) {
      // If the value doesn't exist, add it to the platforms
      updatedPlatforms.push(value);
      // console.log("My name",updatedPlatforms)
    } else {
      // If the value exists, remove it from the platforms
      updatedPlatforms.splice(index, 1);
    }

    // Update the selectedOptions state in the App component by calling onOptionsChange
    onOptionsChange(updatedPlatforms, "platforms");
    const section_features = sectionData.features_listing
    .flatMap((listing) => listing.features)
    .filter((feature) => updatedPlatforms.includes(feature.feature_id))
    .map((feature) => ({
      id: feature.feature_id,
      thumbnail: feature.thumbnail,
      estimated_cost:feature.estimated_cost,
      estimated_hours:feature.estimated_hours
    }));
  // Pass the checked features to the selectedSection prop
  
  setselectSectionData({estimate_id:data1,selectedOptions:{section_id:sectionData.section_id,section_features,}});
   
  };

  const isChecked = (value) => {
  
    return selectedOptions.platforms.includes(value);
  };

 
  return (
    <>
      {sectionData && (
        <div
          id="menu1"
          className="h-100 tab-pane active"
          key={sectionData.section_id}
        >
          <br />
          <h1>{sectionData.section_title}</h1>
          <p dangerouslySetInnerHTML={{ __html: sectionData.section_description }} />
          <div className="platform-panel ps-4">
            {sectionData.features_listing.map((featureCategory, index) => (
               <React.Fragment key={index}>
                <h2>{featureCategory.feature_category_title}</h2>
                {/* <p className="feature-width mb-0">
                  {featureCategory.feature_category_description}
                </p> */}
                 <p className="feature-width mb-0"dangerouslySetInnerHTML={{ __html: featureCategory.feature_category_description }} />
                <div className="platform-list ps-4">
                  {featureCategory.features.map((feature) => (
                    <div key={feature.feature_id}>
                      <label
                        htmlFor={`platform-${feature.feature_id}`}
                        className="cst-checkbox w-100"
                      >
                        <div className="platform-card">
                          <div className="platform-description">
                            <h3>{feature.feature_title}</h3>
                            <p className="feature-width mb-0 description" dangerouslySetInnerHTML={{ __html: feature.feature_description }} />

                          </div>
                          <input 
                            type="checkbox"
                            name="rGroup"
                            value={feature.feature_id}
                            id={`platform-${feature.feature_id}`}
                            checked={isChecked(feature.feature_id)}
                            onChange={() =>
                              handleCheckboxChange(feature.feature_id)
                            }
                            
                          />
                          <span className="checkmark"></span>
                        </div>
                      </label>
                    </div>
                  ))}
                </div>
                </React.Fragment>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Platforms;
