import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Estimate = ({data1, onSubmit, formRef, selectedOptions,Dummy }) => {
  // console.log("console1Id",data1);
 // console.log("console1latest",selectedOptions)
  
  function calculateTotal(selectedOptions, data) {
    let totalHours = 0;
    let totalCost = 0;
  
    // Iterate through the selectedOptions object
    for (const key in selectedOptions) {
      if (Object.prototype.hasOwnProperty.call(selectedOptions, key)) {
        const sectionId = selectedOptions.section_id[key];
        const featureIds = selectedOptions[key];
  
        // Find the section based on section_id
        const selectedSection = data.find(
          (section) => section.section_id === sectionId
        );
  
        // If the section is found, filter the selected features
        if (selectedSection) {
          
          const selectedFeatures = selectedSection.features_listing
            .flatMap((listing) => listing.features)
            .filter((feature) => featureIds.includes(feature.feature_id));
                
          // Accumulate the estimated hours and cost
          selectedFeatures.forEach((feature) => {
            totalHours += feature.estimated_hours;
            totalCost += feature.estimated_cost;
          });
        }
      }
    }
  
    return { totalHours, totalCost };
  }
  
  // Usage:
  const { totalHours, totalCost } = calculateTotal(selectedOptions, Dummy);
  // console.log('Total Estimated Hours:', totalHours);
  // console.log('Total Estimated Cost:', totalCost);
  

// Usage:


// Example usage:



// Example usage:



  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    comment: ''
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const errors = {};

    if (!formData.name.trim()) {
      errors.name = 'Name is required';
    }

    if (!formData.email.trim()) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email is invalid';
    }
    if (!formData.phone.trim()) {
      errors.phone = 'Phone is required';
    } else if (!/^\d+$/.test(formData.phone)) {
      errors.phone = 'Phone should contain only numbers';
    }else if (!formData.comment.trim()) {
      errors.comment = 'Comment is required';
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  
  const handleSubmit = (event) => {
    event.preventDefault();

    const isValid = validateForm();
   
    if (isValid) {
     
      const newDataEntry = {
        requester_name: formData.name,
        requester_email: formData.email,
        requester_phone_number: formData.phone,
        requester_comments: formData.comment,
        total_estimated_hours: totalHours,
    total_estimated_cost:totalCost,
    estimate_id: data1,
    
        
      };
      
      if (formRef.current) {
        onSubmit(newDataEntry);
        setFormData({
          name: '',
          email: '',
          phone: '',
          comment: ''
        });
      }
    }
  };

  return (
    <>
      <div id="menu7" className="h-100 tab-pane active">
       
        <br />
        <h1>View Your Estimates</h1>
        <p className='fw'>
          Congratulations, You have provided all the information. </p><p className='fw'> Your estimate Id is{' '}
          <strong>{data1}</strong>.</p><p className='fw'>Please fill in the information below and Submit the form to submit the Request.</p>
      
        
        {/* Rest of your component UI */}
        <div className="contact px-lg-4 w-lg-75">
          <form ref={formRef} onSubmit={handleSubmit}>
            <div className='row'>
            <div className="col-md-4 col-12 mb-3 mt-3">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <input
                type="text"
                className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                id="name"
                placeholder="Enter name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
              {errors.name && <div className="invalid-feedback">{errors.name}</div>}
            </div>
            <div className="col-md-4 col-12mb-3 mt-3">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                type="email"
                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                id="email"
                placeholder="Enter email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
              {errors.email && <div className="invalid-feedback">{errors.email}</div>}
            </div>

            <div className="col-md-4 col-12 mb-3 mt-3">
              <label htmlFor="phone" className="form-label">
                Phone
              </label>
              <input
                type="text"
                className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                id="phone"
                placeholder="Enter phone"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
              />
              {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
            </div>
            </div>
            

           

            <div className="mb-3 mt-3">
              <label htmlFor="comment">Comments</label>
              <textarea
                className={`form-control ${errors.comment ? 'is-invalid' : ''}`}
                rows={3}
                id="comment"
                name="comment"
                value={formData.comment}
                onChange={handleInputChange}
              />
               {errors.comment && <div className="invalid-feedback">{errors.comment}</div>}
               <br/>
               <h4 style={{opacity:0.6}}>Total Estimated Hours : {totalHours}</h4>
               <h4 style={{opacity:0.6}}>Total Estimated Cost : ${totalCost}</h4>
               <h6>Note: It is only an estimated actual hours and cost. Someone from our Team  will be reaching out soon to Discuss the Costing and Timeline.</h6>
            </div>
            <button type="submit" style={{ display: 'none' }}>
              Submit
            </button>

          </form>
        </div>
      </div>
    </>
  );
};

export default Estimate;
