import React, { useEffect, useState } from 'react'
import logo from "../images/logo.svg"


const Header = ({ id }) => {



  function checkID(id) {
    alert(`Current ID: ${id}`);
  }


  return (
    <header >
      <nav className="navbar navbar-expand-lg bg-white">
        <div className="container-fluid">
          <div className='d-flex flex-column flex-md-row justify-content-between w-100'>
            <a href="/" className="navbar-brand" >
              <img src={logo} alt="Logo" />
            </a>
            <div className="d-flex align-items-center justify-content-center" id="">
              <div className="estimate-code">
              <span>Estimate Id : <strong>{id}</strong></span>
            </div>
              <div className="quote ms-3">
                <a href="https://www.kitlabs.us/request-a-quote/"  target="_blank" className="btn btn-blue">Get a Quote <img src="images/arrow-right.svg" alt="" /></a>
              </div></div>

          </div>
        </div>
      </nav>
    </header>
  )
}

export default Header
