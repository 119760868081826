import React, { useEffect, useRef, useState } from "react";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Domain from "./Components/Domain";
import Platforms from "./Components/Platforms";
import Feature from "./Components/Feature";
import Integration from "./Components/Integration";
import Revenue from "./Components/Revenue";
import Estimate from "./Components/Estimate";
import Sidebar from "./Sidebar";
import Preview from "./Components/Preview";
import Cart from "./Components/Cart";
import Button from "./Button";
import Maintanence from "./Components/Maintanence";
import User from "./Components/User";
import { BallTriangle } from "react-loader-spinner";
import axios from "axios";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  buttonBaseClasses,
} from "@mui/material";
import MUIButton from "@mui/material/Button"; // Import Button from Material-UI with a different name
import NoPage from "./Components/NoPage";
import Success from "./Components/Success";
function App() {

  const [windowLoader,setWindoloader]=useState(false);
  const steps = [
    Platforms,
    Domain,
    Feature,
    Integration,
    User,
    Revenue,
    Maintanence,
    Estimate,
  ];
  const initialComponentIds = {
    platforms: 1,
    domain: 2,
    features: 3,
    integrations: 4,
    users: 5,
    revenues: 6,
    maintenances: 7,
  };

  const totalSteps = steps.length;
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState({
    platforms: [],
    domain: [],
    features: [],
    integrations: [],
    users: [],
    revenues: [],
    maintenances: [],
    section_id: { ...initialComponentIds },
  });
  const [loading, setLoading] = useState(false);
  let newData = [];
  const [apiData, setApiData] = useState();
  const [open, setOpen] = useState(false);
  const [selectOne, setSelectOne] = useState(false); //select atleast one in Platform
  const [selectSectionData, setselectSectionData] = useState(""); //data is selected from particular Platform
  const formRef = useRef(null);

  const baseUrl="https://calculator-admin.kitlabs.us"
  async function fetchDataAndStore() {
    try {
      setWindoloader(true);
     
      const response = await axios.get(
        `${baseUrl}/api/get_all_sections_with_features`,
        { headers: { frontendToken: "costesti" } }
      );

      // console.log("API Response:", response.data.data);

      // Store in local storage
      // localStorage.setItem('widgets', JSON.stringify(response.data.data));
      setApiData(response.data.data);
      setWindoloader(false);

    } catch (error) {
      console.error("Error fetching data:", error);
      
    }
  }

  // Call fetchDataAndStore once DOM is fully loaded

  // });

  // The state apiData will be available here after the asynchronous call is completed

  // Parse localStorage only if it's needed after the asynchronous call
  // const Dummy = JSON.parse(localStorage.getItem('widgets'));

  //get latest estimate id
  const APi2 = async () => {
    const response = await axios.get(
      `${baseUrl}/api/get_latest_estimate`,
      { headers: { frontendToken: "costesti" } }
    );

    setDataId(response.data.data.id);
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const Api = `E${year}-${month}-${day}-${response.data.data.id}`;
    setdata1(Api);
  };
  useEffect(() => {
    // data();
    

    fetchDataAndStore();
    APi2();
  }, []);

  const Every = async () => {
    // for store estimate id
    await axios.post(
      `${baseUrl}/api/save-step-entries`,
      {
        estimate_id: data1,
        selectedOptions: selectSectionData.selectedOptions,
      },
      { headers: { frontendToken: "costesti" } }
    );
  };
  //console.log("DataEverySection",selectSectionData);
  const handleNext = () => {
    if (currentStep < totalSteps - 1) {
      Every();
      setCurrentStep(currentStep + 1);
    }

    setLoading(true);
    setTimeout(() => {
      setLoading(false); // Set loading state to false when the content is ready
    }, 500);
    //  setselectSectionData("");
  };
  //console.log("Platforms",currentStep);for checking Purpose for which step we are currently
  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      setLoading(true);
      setTimeout(() => {
        setLoading(false); // Set loading state to false when the content is ready
      }, 500);
    }
  };

  const handleOptionsChange = (options, type) => {
    // console.log("dataoption",options,type)
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [type]: options,
    }));
    setSelectOne(false);
  };

  const handleRemove = (category, option) => {
    if (!category || !selectedOptions || !apiData) return;
  
    setSelectedOptions((prevOptions) => {
      const updatedOptions = {
        ...prevOptions,
        [category.toLowerCase().replace("selected ", "")]: prevOptions[
          category.toLowerCase().replace("selected ", "")
        ].filter((item) => item !== option),
      };
  
      const allSelectedFeatureIds = Object.values(updatedOptions).flat();
  
      const filteredData = apiData.flatMap((section) => {
        if (
          section.menu_name &&
          category.toLowerCase().replace("selected ", "") ===
            section.menu_name.toLowerCase()
        ) {
          return section.features_listing.flatMap((featureList) =>
            featureList.features.filter((feature) =>
              allSelectedFeatureIds.includes(feature.feature_id)
            ).map((feature) => ({
              ...feature,
              section_id: section.section_id // Add section_id to each feature
            }))
          );
        }
        return [];
      });
  
      const extractedData = filteredData.map((feature) => ({
        id: feature.feature_id,
        thumbnail: feature.thumbnail,
        estimated_cost: feature.estimated_cost,
        estimated_hours: feature.estimated_hours,
        section_id: feature.section_id // Include section_id in the extracted data
      }));
  
      // console.log(extractedData, "DataExtractedData");
      return updatedOptions;
    });
  };
  
  
  
  
  const [data1, setdata1] = useState();
  const [dataId, setDataId] = useState();
  const [res, setRes] = useState(true);

  const handleSubmit = async (Data) => {
    try {
      // Make the API call to save form data
      
      const response = await axios.post(
        `${baseUrl}/api/save-step-entries`,
        {
          requester_name: Data.requester_name,
          requester_email: Data.requester_email,
          requester_phone_number: Data.requester_phone_number,
          requester_comments: Data.requester_comments,
          total_estimated_hours: Data.total_estimated_hours,
          total_estimated_cost: Data.total_estimated_cost,
          estimate_id: Data.estimate_id,
        },
        { headers: { frontendToken: "costesti" } }
      );
      
      // Check response status or handle success scenario
      if (response.status === 200) {
        setRes(true);
        setTimeout(() => {
          setLoading(false); // Set loading state to false when the content is ready
        }, 500);
        setOpen(true);
      } else {
        // Handle other response status codes or unexpected data
        console.error("Unexpected response:", response);
        // Provide appropriate feedback to the user
      }
    } catch (error) {
      // Handle any potential error here
      console.error("Error occurred:", error);
      // Provide feedback to the user about the error
    } finally {
      setLoading(false); // Set loading state to false when the content is ready
    }
  };

  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };

  const handleButtonClick = async () => {
    if (formRef && formRef.current) {
      formRef.current.querySelector('button[type="submit"]').click();
      setLoading(true);
      
    }
  };

  const renderComponent = () => {
    const CurrentComponent = steps[currentStep];
    return (
      <>
        {loading ? (
          <div id="isloading">
            <BallTriangle color="#00BFFF" height={80} width={80} />
          </div>
        ) : (
          
          <CurrentComponent
            onOptionsChange={(options, type) =>
              handleOptionsChange(options, type)
            }
            selectedOptions={selectedOptions}
            data1={data1}
            onSubmit={handleSubmit}
            formRef={formRef}
            newData={newData}
            Dummy={apiData}
            setselectSectionData={setselectSectionData}
          />
        )}
      </>
    );
  };
  // console.log("01",selectedOptions)
  // console.log("SectionDataAPPq",selectSectionData)
  return (
    <>
    <BrowserRouter>
           <Routes>
           <Route index element=
      {!windowLoader ?<div className="wrapper">

        <Header id={data1} />
        <section className="content">
          <div className="container-fluid h-100">
            <div className="content-form h-100 ">
              <Sidebar activeTab={currentStep} Dummy={apiData} />
              <div className="right-content h-100">
                <div className="main-content h-100 align-items-center">
                  <div className="row h-100">
                    <div className="col-xxl-11 col-xl-10  h-100">
                      <div className="row h-100">
                        <div className="col-md-7 col-lg-7 col-xl-9 h-100">
                          <div className="tab-content h-100 tab-content-panel">
                            {renderComponent()}
                            {/* <div className="arrow">
                              <div className="arrow-body"></div>
                              <div className="down-arrow-head"> </div>
                            </div> */}{" "}
                          </div>
                        </div>
                        <Preview
                          selectedOptions={selectedOptions}
                          currentStep={currentStep}
                          Dummy={apiData}
                          baseUrl={baseUrl}
                          isLastStep={currentStep === totalSteps - 1}
                        />


                      </div>
                      
                    </div>
                    <Cart
                      selectedOptions={selectedOptions}
                      onRemove={handleRemove}
                      Dummy={apiData}
                      baseUrl={baseUrl}
                    />

<div className="button-panel order-1">
                  <Button
                    onNext={handleNext}
                    onPrevious={handlePrevious}
                    isLastStep={currentStep === totalSteps - 1}
                    onClick={handleButtonClick}
                    isfirstStep={currentStep}
                    data1={data1}
                    dataId={dataId}
                    setRes={setRes}
                    res={res}
                    selectOne={selectOne}
                    setSelectOne={setSelectOne}
                    selectedOptions={selectedOptions}
                    baseUrl={baseUrl}
                  />
                </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </section>
        {loading ? (
          <div
            style={{
              marginLeft: "350px",
              marginTop: "230px",
            }}
          >
            <BallTriangle color="#00BFFF" height={80} width={80} />
          </div>
        ) : (
         
          <Dialog open={open} onClose={handleClose} maxWidth="lg" >
            <DialogTitle><strong className="Thank">Thank You</strong></DialogTitle>
            <DialogContent>
              <DialogContentText><p className="fw">
                We've received your Cost Estimator request. Our team will review
                the details and get back to you as soon as possible.
                <br /> If you have any further questions or additional
                information to provide, feel free to reach out. We appreciate
                your interest <br />
                and look forward to the opportunity to assist you.</p>
           
                <p className="fw">
          You can view the report at{' '}
         
            <Link to={`estimate/${data1}`}><strong>calculator.kitlabs.us/estimate/{data1}</strong></Link>.
         
        </p>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <MUIButton
                variant="contained"
                color="primary"
                onClick={handleClose}
              >
                Ok
              </MUIButton>
            </DialogActions>
          </Dialog>
        )}
        <Footer />
      </div>
      :<div id="isloading">
            <BallTriangle color="#00BFFF" height={80} width={80} />
          </div>}/>
          <Route path="/estimate/:id" element={<><Success  baseUrl={baseUrl}/><Footer/></>}/>
          <Route path="*" element={<NoPage/>} />
          </Routes>
          </BrowserRouter>

    </>
  );
}

export default App;
