import React, { useState }  from 'react';
import arrowLeft from './images/arrow-left.svg';
import arrowRight from "./images/arrow-right.svg";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Button = ({ onNext, onPrevious, isLastStep,onClick,isfirstStep,data1,dataId,setRes,res, selectedOptions,selectOne,setSelectOne,baseUrl}) => {
  // console.log("first step",isfirstStep)
  // console.log("button",data1);
  // console.log("button2",dataId)
  const notify = () => toast.error("Please select one Platform !", {
    style: { backgroundColor: 'black', color: '#deddd9' },
  });
   const secondresponse=async()=>{
// for store estimate id
    await axios.post(`${baseUrl}/api/save_estimate_id`,{id:dataId,estimate_id:data1},{ headers: { frontendToken: "costesti" }},)
   }
  
   const handleClick = () => {
    if (isfirstStep === 0 && res) {
      secondresponse();
      setRes(false);
    }

    if (selectedOptions.platforms.length > 0) {
      onNext();
      setSelectOne(false);
    } else {
      setSelectOne(true);
      notify(); // Notify when conditions aren't met
    }
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="proceed text-center">
          <ToastContainer />
          {!isfirstStep === false && onPrevious && (
            <button className="btn btn-blue mx-1" onClick={onPrevious}>
              <img src={arrowLeft} alt="" />
              Back
            </button>
          )}
          {!isLastStep ? (
            <button className="btn btn-blue mx-1" onClick={!selectOne ? handleClick : notify}>
              Next <img src={arrowRight} alt="" />
            </button>
          ) : (
            <button type="submit" className="btn btn-blue mx-1" onClick={onClick}>
              Submit
            </button>
          )}
        </div>
      </div>
    </div>
  );
};


export default Button;
