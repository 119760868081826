import React from 'react';
import platform from './images/platform-icon.svg'
import domain from "./images/domain-icon.svg"
import feature from "./images/feature-icon.svg"
import integration from './images/integration-icon.svg';
import user from './images/user-icon.svg';
import maintain from "./images/maintain-icon.svg";
import revenue from "./images/revenue-icon.svg";
import estimate from "./images/calculator.svg";

const Sidebar = ({ activeTab,Dummy }) => {
  //console.log("s", Dummy); 
  // const tabs = [
  //   { id: 'home', label: 'Platforms', icon: platform },
  //   { id: 'menu1', label: 'Domain', icon: domain },
  //   { id: 'menu2', label: 'Feature', icon: feature },
  //   { id: 'menu3', label: 'Integration', icon: integration },
  //   { id: 'menu4', label: 'User', icon: user },
  //   { id: 'menu5', label: 'Revenue', icon: revenue },
  //   { id: 'menu6', label: 'Maintanence', icon: maintain },
  //   { id: 'menu7', label: 'Estimate', icon: estimate },
  // ];

  

  const iconMapping = {
    1: platform,
    2: domain,
    3: feature,
    4: integration,
    5: user,
    6: revenue,
    7: maintain,
    8: estimate,
  };


  const tabs = (Dummy) ? Dummy.map((section) => ({
    label: section.menu_name,
    icon: iconMapping[section.section_id],
    id: section.section_id,
  })) : [];
  

  return (
    <div className="left-content mb-2">
      <div className="sidebar h-100">
        {/* Nav pills */}
        <ul className="nav nav-pills h-100" role="tablist">
          {tabs && tabs.map((tab, index) => (
            <li className="nav-item" key={index}>
              <a
                className={`nav-link ${activeTab === index ? 'active' : ''}`}
                // href={`#${tab.id}`}
              >
                <span className={(activeTab >= index && activeTab !== tabs.length) ? 'tab-green' : ''}>
                  <img src={tab.icon} alt="hello" />
                </span>
                {tab.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
