import React from 'react';

import phone from "../images/default-image-feature-preview.webp";
 // Update this to the correct image
import thankyou from "../images/thankyou.webp";
const Preview = ({ selectedOptions,currentStep ,Dummy,baseUrl,isLastStep}) => {
//  console.log("selectedOptions 12",selectedOptions)  
    // const description1='Stay ahead in the mobile-first world with our custom mobile app development for Apple IOS.';
  const getImageSource = () => {
    const imageSources = {
      
};
const featureImagePaths = {};

// Loop through the dummy data to extract feature image paths
Dummy && Dummy.forEach(section => {
  section.features_listing.forEach(featureCategory => {
    featureCategory.features.forEach(feature => {
      featureImagePaths[feature.feature_id] = feature.featured_image || '/default_image_path.png';
    });
  });
});

// 'featureImagePaths' now contains feature IDs mapped to their respective image paths
//  console.log("dummyImage",featureImagePaths);
Object.keys(featureImagePaths).forEach(featureId => {
  imageSources[featureId] = { image: featureImagePaths[featureId] };
});

// Now 'imageSources' contains the updated paths for the respective feature IDs
// console.log("Paths set",imageSources);
const transformedImageSources = {};
Dummy && Dummy.forEach(section => {
  section.features_listing.forEach(featureCategory => {
    featureCategory.features.forEach(feature => {
      transformedImageSources[feature.feature_id] = { 
        ...imageSources[feature.feature_id], 
        name: feature.feature_title,
        description: feature.feature_description 
      };
    });
  });
});
    let selectedOption="";
    
    if (currentStep===0) {
      selectedOption = selectedOptions.platforms[selectedOptions.platforms.length-1] ;
      // console.log(selectedOptions.platforms[selectedOptions.platforms.length - 1])
    } else if (currentStep===1) {
      selectedOption = selectedOptions.domain[selectedOptions.domain.length - 1];
      // console.log(selectedOptions.domains[selectedOptions.domains.length - 1])
    }else if (currentStep===2) {
      selectedOption = selectedOptions.features[selectedOptions.features.length - 1];
    }
    else if (currentStep===3) {
      selectedOption = selectedOptions.integrations[selectedOptions.integrations.length - 1];
    }
    else if (currentStep===4) {
      selectedOption = selectedOptions.users[selectedOptions.users.length - 1];
    }
    else if (currentStep===5) {
      selectedOption = selectedOptions.revenues[selectedOptions.revenues.length - 1];
    }
    else if (currentStep===6) {
      selectedOption = selectedOptions.maintenances[selectedOptions.maintenances.length - 1];
    }//console.log("Selected Option:", selectedOption);
  
    return transformedImageSources[selectedOption] || phone;
  };
   
  const selectedImageSource = getImageSource();


  return (
    <div className="col-md-5 col-lg-5 col-xl-3 h-100">
  <div className="border-box p-2 h-100 " > {/* Apply border-box and h-100 classes */}
    <div className="platform-visual-panel h-100"> {/* Apply platform-visual-panel and h-100 classes */}
      <div className="platform-screen h-100">
        <h3 className="text-center">Preview</h3>
        <div className="platform-scroll h-100"> {/* Apply platform-scroll and h-100 classes */}
          <div className="platform-visual text-center">
           {!isLastStep && <img
              key={selectedOptions.platforms.join(',') + selectedOptions.domain.join(',')}
              src={selectedImageSource.image ?`${baseUrl}/${selectedImageSource.image}`: phone}
              alt="Selected Preview"
              width="120px"
            />}
            {isLastStep && <img src={thankyou} width="120px"/>}
          </div>
          <div className="platform-feature mt-4 text-center">
            <h5 style={{ textAlign: 'center' }}>
            {selectedImageSource.name ? selectedImageSource.name : null}
            </h5>
            <p
            dangerouslySetInnerHTML={{ __html: selectedImageSource.description ? selectedImageSource.description : null }}/>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  );
};

export default Preview;
